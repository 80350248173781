import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { ContainerBlock, Block} from '../../components/Components'
import { FaArrowAltCircleLeft } from "react-icons/fa"
import Config from '../../customization/Config'
import { ButtonArrow, ButtonBox, ButtonMenu } from '../../components/Buttons'
import gfm from 'remark-gfm'

import cloneDeep from 'lodash/cloneDeep'

import './styles.css'

import ReactMarkdown from 'react-markdown'

const BlockWNavigationBelow = styled(Block)`
    height: calc(100vh - 100px);
    margin: 0;
`

const LabelTab = styled.label`
    display: block;
    padding: 1rem .8rem;
    border-radius: 2px 2px 0 0;
    font-size: 20px;
    font-weight: bold;
    background: #8e44ad;
    cursor: pointer;
    position: relative;
    top: 4px;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    :hover {
        background: #703688;
    }
    :before {
        border: none;
        background-color: inherit;
    }
`

const LinkBack = styled(Link)`
    color: ${() => Config.colors.linkColor};
`

class InfoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNum: 0,
            page: Config.pagesmd.balanced,
            adjustNum: 9,
            checked0: true,
            checked1: false
        }
        
    }

    componentDidMount() {
        let page = []
        if (this.props.match != undefined) {
            const { match: { params } } = this.props

            let adjustNum = cloneDeep(params.page)
            adjustNum = parseInt(adjustNum) + 9

            console.log(adjustNum)

            // Set the page number from link param
            this.setState({pageNum: params.page})
            this.setState({adjustNum: adjustNum})    
        }
         // Set page array
         for (let [index, value] of  Object.entries(Config.pagesmd)) {
            page.push(value)
        }
        this.setState({page: page})
    }

    onChangeChecked() {
        this.setState({
            checked0: !this.state.checked0,
            checked1: !this.state.checked1
        })
    }

    // Control the margins and column widths with margin and colWidth
    render() {
        return (
            <ContainerBlock>
                    <Helmet defaultTitle={`${Config.strings.name} | Info`}>
                        <link href={Config.layout.fontFamily} rel="stylesheet" />
                        <link href={Config.layout.companyFontFamily} rel="stylesheet" />
                        <link rel="icon" href={Config.metadata.icon} sizes="32x32" />
                        <meta description={'Description and suggestions for Chinese Medicine Constitution Types'} />
                        <meta property="og:title" content={Config.strings.name} />
                        <meta property="og:url" content={Config.metadata.domain} />
                        <meta property="og:type" content="website" />
                        <meta property="og:description" content={Config.strings.description} />
                        <meta property="og:image" content={Config.metadata.image} />
                    </Helmet>
                    <BlockWNavigationBelow colWidth="100%">
                        <ul className={"tabs"} role={"tablist"}>
                        <li>
                            <input 
                                type="radio" 
                                name="tabs" 
                                id="tab1" 
                                checked={this.state.checked0} 
                                onChange={this.onChangeChecked.bind(this)}
                            />
                            <LabelTab htmlFor="tab1"
                                role={"tab"} 
                                aria-selected={"true"} 
                                aria-controls={"panel1"} 
                                tabIndex="0">Description</LabelTab>
                            <div id={"tab-content1"}
                                className={"tab-content"} 
                                role={"tabpanel"} 
                                aria-labelledby={"description"} 
                                aria-hidden={"false"}>
                            <ReactMarkdown 
                                remarkPlugins={[gfm]}
                                children={this.state.page[this.state.pageNum]} 
                            />
                            </div>
                        </li>
                    
                        <li>
                            <input 
                                type="radio" 
                                name="tabs" 
                                id="tab2"
                                checked={this.state.checked1}
                                onChange={this.onChangeChecked.bind(this)}
                            />
                            <LabelTab htmlFor="tab2"
                                role={"tab"} 
                                aria-selected={"false"} 
                                aria-controls={"panel2"} 
                                tabIndex="0">Suggestions</LabelTab>
                            <div id={"tab-content2"} 
                                className={"tab-content"}
                                role={"tabpanel"} 
                                aria-labelledby={"recommendation"} 
                                aria-hidden={"true"}>
                            <ReactMarkdown
                                remarkPlugins={[gfm]}
                                children={this.state.page[this.state.adjustNum]} 
                            />
                            </div>
                        </li>
                    </ul>
                </BlockWNavigationBelow>
                <ButtonBox colWidth='100%'>
                    <ButtonArrow>
                        <LinkBack to="/">
                            <FaArrowAltCircleLeft size="48"/>
                        </LinkBack>
                    </ButtonArrow>
                    <ButtonMenu destroy={this.destroy}/>
                </ButtonBox>  
            </ContainerBlock>
        );
    }
}

export default InfoPage
