import React, { Component, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom';
import Storage from '../helpers/Storage'
import Config from '../customization/Config'

// Import share icon https://react-icons.netlify.com/#/icons/fa
import { FaShareAlt, FaQuestionCircle } from 'react-icons/fa'
import { MdDeleteForever, MdInfo } from "react-icons/md";

import "./styles.css"
import "./menu.css"

const Button = styled.div`
	box-shadow:inset 0px 1px 0px 0px #bee2f9;
	background:linear-gradient(to bottom, #63b8ee 5%, #468ccf 100%);
	background-color:#63b8ee;
	border-radius:6px;
	border:1px solid #3866a3;
	display:inline-block;
	cursor:pointer;
	color:#14396a;
	margin: 1rem auto;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #7cacde;
    user-select: none;

    &:hover {
        background:linear-gradient(to bottom, #468ccf 5%, #63b8ee 100%);
        background-color:#468ccf;
    }

    &:active {
        position:relative;
        top:1px;
    }
`

const ButtonBox = styled.div`
    width: ${props => props.colWidth};
    align-self: ${() => Config.layout.alignAddress};
    display: flex;
    justify-content: space-between;
    margin: .4rem auto;
    flex-wrap: wrap;
    position: relative;
    min-height: 48px;
`;

const ShareLink = styled.div`
    color: ${() => Config.colors.socialColor};
    display: inline-block;
    padding: 14px 16px;
    cursor: pointer;
`

class ButtonShare extends Component {
    constructor(props) {
        super(props);

        this.share = this.share.bind(this)
    }


    share() {
        if (navigator.share != undefined) {
            navigator.share({
                title: Config.metadata.name,
                text: Config.metadata.description,
                url: this.props.url
            }).then(() => {
                console.log('Thanks for sharing!')
            })
            .catch(err => {
                console.log(`Couldn't share because of`, err.message)
            })
        } else {
            window.location.href=`https://${window.location.hostname}/share`
        }
    }   

    render() {
        return (
            <ShareLink onClick={() => this.share()}>
                <FaShareAlt />&nbsp;Share app
            </ShareLink>
        )

    }
}

const ButtonArrow = styled.div`
    height: 3rem;
    display: block;
    margin: auto 1rem;
    color: ${() => Config.colors.linkColor};
    animation: inAnimation 500ms ease-in;
    cursor: pointer;
`

const ButtonLink = styled(Link)`
    text-decoration: none;
    color: ${() => Config.colors.linkColor}
    :visited {
        color: ${() => Config.colors.linkColor};
    }
`

const HamburgerMenu = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
`

class ButtonMenu extends Component {
    constructor(props) {
        super (props)

        this.state = { toggleMenu: false }

        this.toggleMenu = this.toggleMenu.bind(this)
    }

    toggleMenu() {
      this.setState(() => {
        return {toggleMenu: !this.state.toggleMenu}
      })
    }

    render() {
        return (
            <HamburgerMenu id="navigation" class="navigation">
                <input type="checkbox" class="navigation__checkbox" id="navi-toggle" checked={this.state.toggleMenu} onChange={this.toggleMenu}/>
                <label for="navi-toggle" class="navigation__mobile navigation__button">
                    <span class="navigation__icon">&nbsp;</span>
                </label>

                <ul class="navigation__list">
                    <li><div><ButtonStorageDestroy toggleMenu={this.toggleMenu} destroy={this.props.destroy} /></div></li>
                    {/*<li><div><MdInfo/>&nbsp;About</div></li>*/}
                    <li><Link to={'/share/about'}><FaQuestionCircle/>&nbsp;About</Link></li>
                    <li><ButtonShare url={`https://${window.location.hostname}`}/></li>
                </ul>
            </HamburgerMenu>
        )
    }                                                                                                                       
}                                                                                                                                                                                                                                              
    
const Modal = ({ destroy, toggleModal, toggleMenu, children }) => {
  return (
    <div>
      <section className="modal-main">
        {children}
        <ButtonConfirm onClick={
            () => {
                toggleMenu()
                destroy()
            }
            }>Yes</ButtonConfirm>
        <ButtonConfirm onClick={toggleModal}>No</ButtonConfirm>
      </section>
    </div>
  );
};

const ButtonConfirm = styled.button`
    border: 1px solid #747474;
    border-bottom: 2px solid #4b4b4b;
    border-right: 2px solid #666565;
    background-color: #f0fbff;
    width: 100%;
    height: 40px;
    border-radius: .25em;
    margin-bottom: .5em;
    z-index: 10;
    font-size: 16px;
    :hover {
        background-color: #fff;
        cursor: pointer;
    }
    :active {
        border: inset;
    }
`

class ButtonStorageDestroy  extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false
        }

        this.storage = new Storage({
            local: Config.strings.name
        })

        this.toggleModal = this.toggleModal.bind(this)
    }

    toggleModal() {
        this.setState({ show: !this.state.show });
    };

    // destroy() {
    //     let localStorage = this.storage.read()
    //     this.storage.destroy()
    // }

    render() {
        return (
            <div onClick={this.toggleModal}><MdDeleteForever/>Clear data
                {(this.state.show) &&
                    <Modal destroy={this.props.destroy} toggleMenu={this.props.toggleMenu} toggleModal={this.toggleModal}>
                        <p>Do you want to clear your answers?</p>
                    </Modal>
                }
            </div>
        )
    }
}


export { Button, ButtonArrow, ButtonBox, ButtonShare, ButtonLink, ButtonMenu, ButtonStorageDestroy };