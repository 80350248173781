
import styled, { keyframes } from 'styled-components';
import Config from '../customization/Config'

const Container = styled.div`
    background-color: ${() => Config.colors.backgroundColor};
    ${() => Config.layout.useGradient && `
        background: linear-gradient(${Config.layout.angleGradient}deg, ${Config.colors.gradientColor01} 0%, ${Config.colors.gradientColor02} 35%, ${Config.colors.gradientColor03} 100%)`};
    
    ${() => Config.layout.useBackgroundImage && `
        background: ${Config.colors.backgroundColor} url(${Config.images.background}) no-repeat fixed center`};
        
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: calc(10px + ${() => Config.layout.defaultFontSize});
    color: ${() => Config.colors.textColor};
    text-align: ${() => Config.layout.alignAddress};
    justify-content: center;
    @media (max-width: 1280px) {
        flex-direction: column;
    }
    padding: .5rem;
`;

export default Container;