import Config from '../customization/Config'
import React,  { Component } from 'react';
import { Button, ButtonLink } from './Buttons'

import { Column, Block, List } from './Components'
import { FaChartPie } from 'react-icons/fa'

import Spinner from './Spinner'
import { Entries } from  './Questions'
import { getResult } from './Scoring'
import Chart from './Chart'

import Storage from '../helpers/Storage'

import cloneDeep from 'lodash/cloneDeep'

class Results extends Component {
    constructor(props) {
        super(props);

        this.storage = new Storage({
            local: Config.strings.name
        })

        this.state = {
            result: null,
            finalJudgement: [],
            page: null,
            chartActive: false
        };

        this.listItems = this.listItems.bind(this)
        this.showPage = this.showPage.bind(this)
        this.toggleChart = this.toggleChart.bind(this)
        this.pieChart = null
    }

    componentDidMount() {
        let answers = cloneDeep(this.props.answers)
        console.log('Answers now', answers)
        if (!this.props.skip) {
            // Get the number of questions to the start of a category
            let partialEntries = Entries.slice(0, 6)
            let sumXueYuEntries = partialEntries.reduce((prev, curr) => prev + curr, 0)
            console.log("Start of Xue Yu category", sumXueYuEntries)

            partialEntries = Entries.slice(0, 2)
            let sumYangXuEntries = partialEntries.reduce((prev, curr) => prev + curr, 0)
            console.log("Start of Yang Xu category", sumYangXuEntries)

            // Copy answers to duplicate questions from other categories
            // Order matters (later insertions first)

            // Answer 8 is copied to the beginning of the Xue Xu questions
            answers.splice(sumXueYuEntries, 0, answers[7])
            
            // Answer 12 is copied to the beginning of the Yang Xu questions
            answers.splice(sumYangXuEntries, 0, answers[11])  

            // Answer 2 is copied to the beginning of the Qi Xu questions
            answers.splice(Entries[0], 0, answers[1])
            
            // Answer 3 is copied to the beginning of the Qi Xu questions
            answers.splice(Entries[0], 0, answers[2])
            
            // Answer 4 is copied to the beginning of the Qi Xu questions
            answers.splice(Entries[0], 0, answers[3])

            // Reverse answers for Balanced type after copying
            let reverseValues = [1,2,3,6,7]
            for (let x = 0; x < reverseValues.length; x++) {
                answers[reverseValues[x]] = Math.abs(answers[reverseValues[x]] - 9).toString()
            }
        }

        console.log("Result prop", this.props.result)
        if (!this.props.result) {
            getResult({ answers: answers, entries: Entries }).then((resp) => {
                console.log('Response body', resp)
                console.log('Finished transaction')
                

                // Clear previous saves, since latest save is automatically loaded
                this.storage.destroy()
                
                // Store result locally so we can call it back up if answers have not changed
                this.storage.add({ 
                    "answers": this.props.answers,
                    "result": resp,
                })

                this.setState((state) => {
                    return {result: resp}
                }, () => this.listItems())
            });
        } else {
            this.setState((state) => {
                        return { result: this.props.result }
                    }, () => this.listItems())
        }
    }

    listItems() {
        let listItems = [],
        count = 0

        for (let i = 0; i < this.state.result.pageLink.length; i++) {
          if (this.state.result.pageLink[i] === 1) {
            listItems.push(
            <li key={`info${i}`}><ButtonLink 
                style={{
                    color: 'rgb(186 239 255)',
                    alignItems: 'center',
                    display: 'flex'
                }}
                to={`/info/${i}`}>{this.state.result.judgement[count]}</ButtonLink></li>
            )
            count++
          }
        }
        
        this.setState({finalJudgement: listItems})
        console.log('This state result', this.state.result.result)
        this.pieChart = <Chart result={this.state.result.result}/>
    }

    showPage(pageLink, start) {
        window.location.href=`${Config.serviceMap.frontend}/info/${pageLink.indexOf(start, 1)}`
    }

    toggleChart() {
        this.setState({chartActive: !this.state.chartActive})
    }

    render() {
        return (
            <Block colWidth="100%">
                <Column colWidth="100%">
                    <h2>Results</h2>
                </Column>

                {this.state.result ?
                    <Column colWidth="100%">
                        <List>
                            {this.state.finalJudgement}
                            <li 
                                onClick={() => this.toggleChart()}
                                style={{
                                    color: Config.colors.linkColor,
                                    alignItems: 'center',
                                    display: 'flex'
                                    }}
                            >
                                <FaChartPie/>&nbsp;Chart
                            </li>
                        </List>
                        
                    </Column>
                    :
                    <Spinner/>
                }
                {this.state.chartActive &&
                    <Column colWidth="100%">
                        {this.pieChart}
                    </Column>
                }
                <Column colWidth="100%" className='border'>
                    <p>The results of this test are not intended for diagnosis and treatment. Please consult a professional acupuncturist.</p>
                </Column>
            </Block>
            
        );
    }
}

export default Results;