import React from 'react'
import styled, { keyframes } from 'styled-components';
import Results from '../../components/Results'
import { Column, Container, Block, BlockSelect, H1Name, H2Title, Content, CompanyLinks, PrintLink, ExtLink, Flip, List } from '../../components/Components'
import ReactToPrint from 'react-to-print';
import { ButtonArrow, ButtonBox, Button, ButtonShare } from '../../components/Buttons'
import { Entries } from  '../../components/Questions'
import Config from '../../customization/Config'

let answers = []
let numEntries = Entries

class ResultPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answers
        };
    }

    componentWillMount() {
        const { match: { params } } = this.props;
        let answers = []
        for (let x = 0; x < params.answers.length; x++) {
            answers.push(params.answers[x])
        }

        let answersInt = answers.map(function(answer) {
            return parseInt(answer, 10);
        });

        this.setState({ answers: answersInt })
    }

    render() {
        return (
            <Container>
                <Results answers={this.state.answers} skip={true} ref={el => (this.componentRef = el)} />
            </Container>
        )
    }
}

export default ResultPage;