import logo from './logo.svg';
import './App.css';
import Font from './helpers/Font'
import HttpsRedirect from 'react-https-redirect';
import styled from 'styled-components';
import Router from './Router';
import Config from './customization/Config'
import React from 'react'
import Container from './components/Container'

// console.log('Config', Config)


const ConfigContext = React.createContext();

function App() {
  return (
    <ConfigContext.Provider value='hello'>
      <HttpsRedirect>
        <Container>
            <Router />        
        </Container>
      </ HttpsRedirect>
    </ConfigContext.Provider>
  );
}

export default App;
