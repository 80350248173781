
import Config from '../customization/Config'
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
    background-color: ${() => Config.colors.backgroundColor};
    ${() => Config.layout.useGradient && `
        background: linear-gradient(${Config.layout.angleGradient}deg, ${Config.colors.gradientColor01} 0%, ${Config.colors.gradientColor02} 35%, ${Config.colors.gradientColor03} 100%)`};
    
    ${() => Config.layout.useBackgroundImage && `
        background: ${Config.colors.backgroundColor} url(${Config.images.background}) no-repeat fixed center`};
        
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: calc(10px + ${() => Config.layout.defaultFontSize});
    color: ${() => Config.colors.textColor};
    justify-content: space-around;
    @media (max-width: 1280px) {
        flex-direction: column;
    }
    padding: .5rem;
    margin: auto;
    max-width: 1200px;
`;

const ContainerBlock = styled.div`
    background-color: ${() => Config.colors.backgroundColor};
    ${() => Config.layout.useGradient && `
        background: linear-gradient(${Config.layout.angleGradient}deg, ${Config.colors.gradientColor01} 0%, ${Config.colors.gradientColor02} 35%, ${Config.colors.gradientColor03} 100%)`};
    
    ${() => Config.layout.useBackgroundImage && `
    background: ${Config.colors.backgroundColor} url(${Config.images.background}) no-repeat fixed center`};
    font-size: calc(10px + ${() => Config.layout.defaultFontSize});
    color: ${() => Config.colors.textColor};
    height: 100vh;
    padding: 0 .5em;
    width: 100%;
`;

const Column = styled.div`
    width: ${props => props.colWidth};
    align-self: ${() => Config.layout.alignAddress};
    display: flex;
    justify-content: ${() => Config.layout.alignAddress};
    margin: .4rem auto;
`;

const Block = styled(Column)`
    user-select: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const BlockSelect = styled(Column)`
    user-select: text;
    flex-wrap: wrap;
`

const H1Name = styled.h1`
    font-size: calc(10px + 4vmin);
    letter-spacing: ${() => Config.layout.nameLetterSpacing};
    text-indent: ${() => Config.layout.nameLetterSpacing};
    width: 100%;
    margin: auto;
    ${() => Config.layout.alignAddress != 'center' && `
        margin: auto 1rem auto 1rem`};
    text-align: ${() => Config.layout.alignAddress};
    font-weight: 400;
`;

const H2Title = styled.p`
    font-size: calc(10px + 2vmin);
    letter-spacing: ${() => Config.layout.titleLetterSpacing};
    text-indent: ${() => Config.layout.titleLetterSpacing};
    margin: 0 1rem 1.5rem;
    ${() => Config.layout.alignAddress === 'right' && `
        transform: translateX(${Config.layout.titleLetterSpacing})`};
    ${() => Config.layout.alignAddress != 'right' && `
        margin-right: 1rem`};
    ${() => Config.layout.alignAddress === 'left' && `
        text-indent: 0`};   
    width: 100%;
    text-align: center;
`;

const Content = styled.div`
    padding-bottom: 8px;
`;

const CompanyLinks = styled.div`
    width: 100%;
    margin: 1rem 0.5rem 0 0.5rem;
    display: flex;
    justify-content: center
    ${() => Config.layout.alignAddress === 'right' && `
        justify-content: flex-end`};
    ${() => Config.layout.alignAddress === 'left' && `
        justify-content: left`};
    flex-wrap: wrap;
    `;

const PrintLink = styled.div`
    height: 3rem;
    display: block;
    margin: auto 1rem;
    text-decoration: none;
    color: ${() => Config.colors.linkColor};
    cursor: pointer;
`;

const ExtLink = styled.a`
    color: ${() => Config.colors.linkColor};
    text-decoration: none;
    padding: 0 8px 8px 8px;
    display: flex;
    text-align: ${() => Config.layout.alignAddress};
    flex-wrap: wrap;
    width: ${props => props.colWidth};
    margin: 0 0 .5em 0;
    margin: ${props => props.margin};
    justify-content: center;
`;

const Flip = styled(Link)`
    height: 3rem;
    display: block;
    margin: auto 1rem;
    color: ${() => Config.colors.linkColor};
`;

const List = styled.ul`
    list-style: none;
    margin: auto;
    text-align: left;
    padding: 0;
    li {
        margin-bottom: 1rem;
        border: 2px solid #ffffff9e;
        border-radius: 4px;
        padding: .5rem;
    }
`

const BlockSlider = styled.div`
    text-align: left;
    height: 200px;
`

const BlockCenter = styled(Column)`
    display: flex;
    justify-content: center;
    margin: 0;
`

const BlockRadio = styled(Column)`
    display: flex;
    justify-content: center;
    margin: 0;
`

const HalfwayLine = styled.div`
    position: relative;
    bottom: 50%;
    border-top: .2rem dotted #fff;
    opacity: .7;
`

const LabelRadio = styled.label`
    font-size: calc(16px + (1800vw - 5760px)/3520);
    font-weight: bold;
    line-height: 1;
    cursor: pointer;
    display: block;
    padding: .5rem 0 .5rem 3rem;
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, .3);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.15s ease;
    color: #fff;
    min-width: 100%;
    text-align: left;
    :first-of-type {
	    border: 0;
    }
    :before {
        content: "";
        position: absolute;
        left: 1rem;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 0.2rem solid #ccc;
        background-color: #29054f;
    }
`

const Image = styled.img`
    width: 100%;
    padding: 0 2rem;
` 

export { Column, Container, Block, BlockSelect, H1Name, H2Title, Content, CompanyLinks, PrintLink, ExtLink, Flip, List, BlockSlider, BlockRadio, BlockCenter, HalfwayLine, LabelRadio, ContainerBlock, Image };