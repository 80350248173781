const Config = {
        "answerSet01": {
            "answer01": "Always or usually",
            "answer02": " ",
            "answer03": " ",
            "answer04": " ",
            "answer05": " ",
            "answer06": " ",
            "answer07": " ",
            "answer08": " ",
            "answer09": " ",
            "answer10": "Never or rarely"
          },
          "colors": {
            "linkColor": "#47d0fa",
            "backgroundColor": "#050c16",
            "textColor": "#fcfcfc",
            "iconColor": "#902c2c",
            "gradientColor01": "#060b4a",
            "gradientColor02": "#330659",
            "gradientColor03": "#0c0431",
            "socialColor": "#c3eae6"
          },
          "images": {
            "logo": "85xqq-PCHS_Icon_Gradient.svg",
            "background": "coolbackground.png",
            "logowide": "h0d0v-PCHS_Logo_C_White_Transparent.png"
          },
          "layout": {
            "fontFamily": "https://fonts.googleapis.com/css?family=Roboto",
            "alignAddress": "center",
            "useGradient": true,
            "angleGradient": "15",
            "useBackgroundImage": false,
            "titleLetterSpacing": "",
            "nameLetterSpacing": "",
            "companyFontFamily": "https://fonts.googleapis.com/css?family=Dosis",
            "companyFontSize": "1.2vmin",
            "addressFontSize": "1.25vmin",
            "hasBack": true,
            "showAddress": false,
            "showPhone": false,
            "showEmail": false
          },
          "pagesmd": {
            "balanced": "Balanced Type\n=============\n\nCharacteristics\n---------------\n\n-   normal constitution with good sleep, cheerful personality, good social and natural adaptability\n\n-   yin, yang, qi, and blood reconciled\n\n-   body shape well-balanced\n\n-   complexion with spirit and luster\n\n-   energetic\n\nPopulation\n----------\n\n-   men more than women\n\n-   young people more than older people\n\nPredisposition to disease\n-------------------------\n\n-   does not easily get sick\n\n## Physical features\n\n-   body shape is well-balanced and robust\n\nSigns and Symptoms\n------------------\n\n-   skin is moist\n\n-   hair is thick and lustrous\n\n-   eyes are clear and bright with Shen (spirit)\n\n-   nose is bright and lustrous\n\n-   olfactory senses (smell) is acute\n\n-   lips are moist and sleek with normal color\n\n-   energetic / not easy to fatigue\n\n-   tolerant to cold and heat\n\n-   sleep is sound and restful\n\n-   appetite and digestion are good\n\n-   elimination is normal\n\n-   tongue is pink-red, coating thin and white\n\n-   pulse is steady with normal force\n\n## Psychological characteristics\n\n-   easy-going and cheerful\n\n## Incidence tendency\n\n-   fewer diseases\n\n## Adaptability to the external environment\n\n-   Strong adaptability to natural and social environments",
            "qixu": "# Qi Deficiency Type\n\nCharacteristics\n---------------\n\n-   soft and loose muscles\n\n-   weak voice\n\n-   generally introverted, emotionally unstable, timid, and not adventurous\n\n-   lack of vitality/energy\n\n\nPopulation\n----------\n\n-   people who have been engaged in intensive labor for long hours\n\n-   hard working people (i.e. students)\n\nPredisposition to disease\n-------------------------\n\n-   easy to catch a cold\n\n-   weak resistance to disease\n\n-   difficult to heal after being sick\n\n-   prone to visceral drooping (such as sagging stomach, or prolapse)\n\nSigns and Symptoms\n------------------\n\n-   shortness of breath\n\n-   easy to fatigue / lack of energy\n\n-   easy to sweat / spontaneous sweating\n\n-   pale and scalloped tongue\n\n-   weak pulse\n\n## Psychological characteristics\n\n-   introverted personality\n\n-   lack of adventurous spirit\n\n## Incidence tendency\n\n-   easy to catch cold\n\n-   organ prolapse\n\n-   slow recovery from illness\n\n## Adaptability to the external environment\n\n-   intolerance to wind, cold, heat, and dampness",
            "yangxu": "# Yang Deficiency Type\n\nCharacteristics\n---------------\n\n-   muscles are soft and loose\n\n-   cold hands and feet\n\n-   wears more layers of clothes than others\n\n-   aversion to cold environment\n\n-   aversion to cold food and drinks\n\n-   calm, introverted, quiet\n\nPopulation\n----------\n\n-   more women than men\n\n-   long-term preference for cold food or drinks\n\nPredisposition to disease\n-------------------------\n\n-   edema\n\n-   diarrhea\n\nSigns and Symptoms\n------------------\n\n-   aversion to cold\n\n-   cold hands and feet\n\n-   prefer to eat warm food\n\n-   lack of energy\n\n-   pale and puffy tongue\n\n-   deep and slow pulse\n\n*Psychological characteristics*\n\n-   quite and calm\n\n-   introverted personality\n\n*Incidence tendency*\n\n-   phlegm\n\n-   swelling\n\n-   diarrhea\n\n*Adaptability to the external environment*\n\n-   resistance to summer\n\n-   not resistant to winter\n\n-   susceptible to wind, cold, and dampness\n",
            "yinxu": "# Yin Deficiency Type\n\nCharacteristics\n---------------\n\n-   not resistant to heat\n\n-   often feels dry eyes, dry mouth, dry throat, dry skin, and often has dry stools\n\n-   often feels the heat in the hands and feet\n\n-   cheeks flushed or reddish\n\n-   always wants to drink water\n\n-   often experiences insomnia\n\n-   outgoing, but impatient, irritable, impetuous\n\nPopulation\n----------\n\n-   more common in students and young people\n\n-   people who eat mainly fried food or barbecue\n\n-   people addicted to tobacco and alcohol\n\n-   people with high level of stress\n\nPredisposition to disease\n-------------------------\n\n-   cough\n\n-   Sjogren's syndrome\n\n-   hyperthyroidism\n\n-   dry mouth and throat\n\n-   hands and feet heat\n\nPhysical characteristics\n------------------------\n\n-   lean body shape\n\nSigns and Symptoms\n------------------\n\n-   hands and feet heat\n\n-   dry mouth and throat\n\n-   slightly dry nostril\n\n-   prefer to cold drinks\n\n-   dry stool\n\n-   red tongue with less moisture\n\n-   pulse thin and rapid\n\n*Psychological characteristics*\n\n-   short-tempered\n\n-   extroverted personality\n\n-   active / lively\n\n*Incidence tendency*\n\n-   susceptible to fatigue,\n\n-   susceptible to loss of sperm\n\n-   susceptible to insomnia\n\n*Adaptability to the external environment*\n\n-   resistant to winter\n\n-   not resistant to summer\n\n-   not tolerate heat, summer-heat, and dryness ",
            "dampPhlegm": "# Damp and Phlegm Type\n\nCharacteristics\n---------------\n\n-   body shape is round and the abdomen is plump and soft\n\n-   easy to sweat with sticky\n\n-   the skin is oily, often feel a layer of oil on the face\n\n-   the eyes are swollen and easy to sleep\n\n-   gentle and steady\n\n-   good at patience\n\n-   mouth has a sticky and greasy feeling\n\n-   sticky greasy tongue coating\n\nPopulation\n----------\n\n-   middle-aged and elderly people who prefer to sweet foods\n\n-   men more than women\n\n-   people with less physical exercise\n\n-   phlegm and damp accumulation\n\n-   mouth with sticky greasy feelings\n\n-   sticky greasy tongue coating\n\nPredisposition to disease\n-------------------------\n\n-   dizziness, chest pain, obesity\n\n-   susceptible to coronary heart disease, hypertension, hyperlipidemia,\n    diabetes\n\n\nPhysical characteristics\n------------------------\n\n-   big/wide body shape\n\n-   soft and loose abdomen\n\nSigns and Symptoms\n------------------\n\n-   oily facial skin\n\n-   abundant sticky sweats\n\n-   chest tightness\n\n-   excessive phlegm\n\n-   sticky or sweet feelings in the mouth\n\n-   prefer to eat sweet and sticky food\n\n-   greasy tongue coating\n\n-   slippery pulse\n\n## Psychological characteristics\n\n-   gentle personality\n\n-   steady with patience\n\n## Incidence tendency\n\n-   susceptible to diabetes, stroke, chest pain\n\n## Adaptability to the external environment\n\n-   poor adaptability to the rainy season and wet environment ",
            "dampHeat": "# Damp and Heat Type\n\nCharacteristics\n---------------\n\n-   the face and nose tip are always shiny\n\n-   the face is prone to acne, and the skin easily gets itchy\n\n-   often experiences bitter taste in the mouth\n\n-   bad breath\n\n-   sticky stools, yellow urine\n\n-   temper is more impetuous irritable\n\n-   damp-heat trapped inside\n\n-   thick-oily facial skin\n\n-   bitterness in the mouth\n\n-   yellow greasy tongue coating\n\nPopulation\n----------\n\n-   people who eat a high-calorie diet\n\n-   Young people who like to eat fried barbecue foods or like alcohol and\n    tobacco\n\n-   coupled with increased pressure on life\n\n-   more common in students and business service personnel.\n\nPredisposition to disease\n-------------------------\n\n-   scabies\n\n-   jaundice\n\n## Physical characteristics\n\n-   moderate or slightly thin body shape\n\nSigns and Symptoms\n------------------\n\n-   thick-oily facial skin\n\n-   easy to grow acne\n\n-   dry and bitter mouth\n\n-   heaviness and drowsiness feelings\n\n-   constipated with sticky or dry stool\n\n-   yellow and scanty urine\n\n-   moist scrotum for male\n\n-   excessive leukorrhea (vaginal discharge) for female\n\n-   red tongue with yellow greasy coating\n\n-   slippery and rapid pulse\n\n## Psychological characteristics\n\n-   easy to feel vexation/ irritation\n\n-   impatient\n\n## Incidence tendency\n\n-   susceptible to sores, jaundice, UTI / urination with burning sensation\n\n## Adaptability to the external environment\n\n-   poor adaptability to the hot and humid environment",
            "blood": "# Blood Stagnation/Stasis Type\n\nCharacteristics\n---------------\n\n-   skin is often dry and rough\n\n-   eyes often have thin red veins\n\n-   gums are easy to bleed\n\n-   easy to be irritated, forgetful, and impatient\n\n-   poor blood circulation\n\n-   dark and dusky skin color\n\n-   purplish tongue color\n\n\nPopulation\n----------\n\n-   white-collar (brain) workers\n\n-   women more than men\n\nPredisposition to disease\n-------------------------\n\n-   bleeding\n\n-   stroke\n\n-   heart disease, coronary heart disease\n\n## Physical characteristics\n\n-   not specific\n\nSigns and Symptoms\n------------------\n\n-   dark and dusky complexion, prone to ecchymosis\n\n-   dull and dark lips\n\n-   dark and dusky tongue with darker spots\n\n-   dark purple and thickening sublingual veins\n\n-   choppy pulse\n\n## Psychological characteristics\n\n-   easily irritated\n\n-   forgetful\n\n## Incidence tendency\n\n-   susceptible to lumps, tumors, pain, blood related diseases\n\n## Adaptability to the external environment\n\n-   intolerance to cold",
            "qiyu": "# Qi Stagnated Type\n\nCharacteristics\n---------------\n\n-   Qi stagnation\n\n-   depressed and easily affected emotions\n\n-   slim body shape\n\n-   often feel low mood / depressed\n\n-   often sigh for no reason\n\n-   often chest tightness\n\n-   easy to have insomnia\n\n-   more sentimental, melancholy and fragile, easy to panic,\n\nPopulation\n----------\n\n-   mostly in young people\n\n-   women are significantly more than men\n\nPredisposition to disease\n-------------------------\n\n-   insomnia\n\n-   depression\n\n-   psychological or psychiatric diseases\n\n## Physical characteristics\n\n-   thin body shape mainly\n\nSigns and Symptoms\n------------------\n\n-   depressed\n\n-   easily affected emotionally\n\n-   unhappy\n\n-   pale red tongue with thin white coating\n\n-   wiry pulse\n\n## Psychological characteristics\n\n-   introverted personality\n\n-   sensitive\n\n-   over thinking\n\n## Incidence tendency\n\n-   susceptible to psychological diseases\n\n## Adaptability to the external environment\n\n-   poor adaptability to mental stimulation\n\n-   poor adaptability to rainy weather\n",
            "hypersensitive": "# Special Type\n\nCharacteristics\n---------------\n\n-   allergies to pollen or certain foods\n\n-   mostly caused due to genetic and heredity\n\n-   congenital disorders\n\n-   physiological defects\n\n-   allergic reactions\n\nPopulation\n----------\n\n-   people with allergies are susceptible to drugs, food, smell, pollen, and\n    seasonal changes\n\n-   any person with a hereditary disease\n\nPredisposition to disease\n-------------------------\n\n-   drug allergy\n\n-   hay fever\n\n-   asthma\n\nPhysical characteristics\n------------------------\n\n-   no special characteristics for allergic constitutional type\n\n-   congenital disorders may have deformities or physiological defects\n\nSigns and Symptoms\n------------------\n\n-   For allergic constitution, asthma, itchy throat, stuffy nose, sneezing\n\n-   For patients with hereditary diseases, there is usually afamily history of\n    the disease\n\nPsychological characteristics\n-----------------------------\n\n-   different conditions vary\n\nIncidence tendency\n------------------\n\n-   allergic constitution is susceptible to asthma, urticaria, hay fever and\n    allergies from medications\n\n-   hereditary diseases such as hemophilia, congenital stupidity\n\n-   fetal stage diseases such as tardy development\n\nAdaptability to the external environment\n----------------------------------------\n\n-   poor adaptability to allergies\n\n-   poor adaptability to the allergic season\n",
            "balancedAdjustment": "Balanced Type\n=============\n\nDietary suggestion\n------------------\n\n-   the diet should be balanced\n\n-   avoid cold or overheated food\n\n-   the food should be fresh\n\n-   avoid eating too much\n\n-   avoid feeling very hungry\n\n-   eat more grains, fruits and vegetables\n\n-   eat less oily and spicy food\n\nExercise suggestion\n-------------------\n\n-   intensive exercise (i.e. running) may be appropriate for young people\n\n-   moderate walking / hiking\n\n-   Tai-chi would be appropriate for elderly people\n\nLife-style suggestion\n---------------------\n\n-   not specific\n\nAcupressure\n-----------\n\n-   not specific",
            "qixuAdjustment": "Qi Deficiency Type\n==================\n\nDietary suggestion\n------------------\n\n-   foods with qi-replenishing and qi-strengthening such as soybeans, white\n    lentils, and chicken, mushrooms, jujube, longan, honey\n\n-   eat **less** foods that have qi-consuming effect such as betel nut, spinach,\n    and raw radish\n\nExercise suggestion\n-------------------\n\n-   gentle movements such as walking, and Tai Chi\n\n-   less intensive exercise\n\n-   avoid heavy sweating\n\nAcupressure\n-----------\n\n-   ST 36, Ren-6",
            "yangxuAdjustment": "Yang Deficiency Type\n====================\n\nDietary suggestion\n------------------\n\n-   eat more food that “warms” body, such as beef, lamb, onions, ginger, garlic,\n    pepper, leeks, chili peppers, etc.\n\n-   eat less food that may “cool” body, such as cucumbers, clam, pear, and\n    watermelon.\n\n-   avoid green tea\n\nLife-style suggestion\n---------------------\n\n-   avoid AC especially during the summer\n\n-   appropriate use dry sauna and hot spring bath\n\n-   talk to others\n\n-   listen to the exciting, high-pitched, heroic type of music\n\n-   keep warm in autumn and winter, especially in the cold days\n\n-   keep the feet, back and lower abdomen warm (even with warm pad)\n\nExercise suggestion\n-------------------\n\n-   soothing and gentle exercises, such as jogging, walking, Tai-chi\n\nAcupressure\n-----------\n\n-   Ren-4, Ren-6, ST-36, KD-1",
            "yinxuAdjustment": "Yin Deficiency Type\n===================\n\nDietary suggestion\n------------------\n\n-   eat more lean pork, duck, mung bean, melon and other cool and moisturizing\n    foods\n\n-   eat less mutton, leek, pepper, onion, garlic, sunflower seed and other warm\n    and dry foods\n\nLife-style suggestion\n---------------------\n\n-   keep a regular lunch break at noon (better to take a nap), avoid staying up\n    late at night\n\n-   avoid strenuous exercise and working in hot summer\n\n-   less sexual activity\n\n-   balance the emotions, keep calming down to good or bad things\n\n-   practice calligraphy, play chess, enjoy nature\n\n-   listen to soothing, soft, and lyrical music\n\nExercise suggestion\n-------------------\n\n-   Tai-chi, Qigong or medium-strength physical exercise\n\n-   avoid sweating too much and the dry sauna\n\nAcupressure\n-----------\n\n-   KD-3",
            "dampPhlegmAdjustment": "Damp and Phlegm Type\n====================\n\nDietary suggestion\n------------------\n\n-   the diet should be light in terms of amount and taste\n\n-   eat less fat and sweet, sticky, greasy food\n\n-   eat more onions, garlic, seaweed, kelp, melon, radish, kumquat, mustard\n\nLife-style suggestion\n---------------------\n\n-   more outdoor activities\n\n-   more morning sunbathing\n\n-   clothing should be breathable\n\nExercise suggestion\n-------------------\n\n-   should be conducted gradually and progressively according to the individual\n    situation\n\n-   regular daily exercise for long-term\n\nAcupressure\n-----------\n\n-   ST-36, ST-40, SP-9, Ren-6, Ren-12",
            "dampheatAdjustment": "Damp and Heat Type\n==================\n\nDietary suggestion\n------------------\n\n-   the diet should be light in terms of amount and taste\n\n-   eat more foods such as red beans, mung beans, celery, spinach, leeks,\n    cucumbers, watermelon, and alfalfa\n\n-   eat less spicy food\n\nLife-style suggestion\n---------------------\n\n-   quit tobacco and alcohol\n\n-   avoid staying up late, or getting tired\n\n-   reducing outdoor activities during summer\n\nExercise suggestion\n-------------------\n\n-   high-intensity, high-volume exercise, such as long-distance running,\n    swimming, climbing, all kinds of ball games\n\n-   martial arts\n\nAcupressure\n-----------\n\n-   LI-11, LV-2",
            "bloodAdjustment": "Blood Stagnation/Stasis Type\n============================\n\nDietary suggestion\n------------------\n\n-   eat more hawthorn, vinegar, roses\n\n-   eat less animal fat\n\n-   eat black beans, seaweed, kelp, seaweed, radish, carrot, kumquat, orange,\n    pomelo, peach, plum, hawthorn, vinegar\n\n-   rose tea, green tea\n\n-   foods help blood circulation\n\nLife-style suggestion\n---------------------\n\n-   keep enough but not too much sleep\n\n-   massage can help to smooth the meridians, relieve pain, stabilize emotions,\n    and enhance the body's function\n\n-   people with bloody constitution should see a doctor immediately if they have\n    chest tightness, difficulty in breathing, and rapid increase in pulse rate\n\nExercise suggestion\n-------------------\n\n-   aerobics\n\n-   sports that can help promote blood circulation, such as Tai Chi, dancing,\n    walking\n\nAcupressure\n-----------\n\n-   LV-3, GB-39, SP-10, PC-6, HT-5",
            "qiyuAdjustment": "Qi Stagnated Type\n=================\n\nDietary suggestion\n------------------\n\n-   eat more wheat, artemisia, onion, garlic, kelp, seaweed, radish, kumquat,\n    hawthorn\n\n-   drink rose tea\n\n-   avoid drinking tea, coffee and other energy drinks before going to bed\n\nLife-style suggestion\n---------------------\n\n-   should not stay at home always\n\n-   should participate in group style sports programs or social clubs\n\n-   making friends and talking to friends often\n\nExercise suggestion\n-------------------\n\n-   try to increase outdoor activities, such as running, hiking, swimming, and\n    martial arts.\n\n-   participate sports in group styles\n\n-   short walking as the first thing to do in the early morning (best between\n    5-8 am)\n\nAcupressure\n-----------\n\n-   LI-4, LV-3, GB-8, GB channels along the rib-sides and legs",
            "hypersensitiveAdjustment": "Hypersensitivity Type\n=====================\n\nDietary suggestion\n------------------\n\n-   eat more foods with beneficial gas and solid table,\n\n-   eat less buckwheat (including sensitizing substance buckwheat fluorescein),\n    broad beans, white lentils, beef, goose, squid, shrimp, crab, eggplant,\n    wine, pepper, tea, coffee and other spicy products\n\n-   diet should be light and balanced\n\nLife-style suggestion\n---------------------\n\n-   the room should be well ventilated, clean; bedding and bed sheets should be\n    washed frequently to prevent allergies to dust mites\n\n-   avoid living in the room immediately after the interior decorations\n\n-   avoid pets\n\n-   establish a regular daily schedule\n\nExercise suggestion\n-------------------\n\n-   reduce outdoor activity time during the spring to prevent allergies to\n    pollen\n\n-   actively participate in various physical exercises to avoid emotional stress\n\nAcupressure\n-----------\n\n-   LU-9, LI-11, LI-20, ST-36\n"
          },
          "questionsTypeBalanced": {
            "question01": "Are you energetic?",
            "question02": "Are you tired?",
            "question03": "Is your voice weak (meaning others often ask you to speak up, especially in larger groups or rooms)?",
            "question04": "Do you feel depressed?",
            "question05": "Do you feel cold more than others around you (family, friends, corworkers)?",
            "question06": "Can you adapt to changes in the natural and social environment?",
            "question07": "Are you prone to insomnia?",
            "question08": "Is it easy for you to forget things (forgetfulness)?"
          },
          "questionsTypeQiXu": {
            "question01": "Are you prone to shortness of breath?",
            "question02": "Do you have heart palpitations?",
            "question03": "Are you dizzy?",
            "question04": "Are you more likely to catch cold than others around you (family, friends, coworkers)?",
            "question05": "Do you prefer to be quiet and feel that it takes a lot of energy to talk?",
            "question06": "Do you sweat spontaneously or from minimal exertion?"
          },
          "questionsTypeYangXu": {
            "question01": "Are your hands and feet cold?",
            "question02": "Are your belly, back, waist and/or knees cold?",
            "question03": "Do you try to avoid cold temperature and wear warm clothes more than others around you (family, friends, coworkers)?",
            "question05": "Do you feel uncomfortable after eating cold food or drinks?",
            "question06": "Do you get diarrhea or loose stool after getting chilled or consuming cold food or drinks (including raw foods such as salads)?",
            "question04": "Do you feel cold more than others?"
          },
          "questionsTypeYinXu": {
            "question01": "Do your hands and feet feel too warm or even hot?",
            "question02": "Does your body and face feel too warm or even hot?",
            "question03": "Does your skin or lips feel dry?",
            "question04": "Is the color of your lips redder than normal (meaning redder than most other people)?",
            "question05": "Are you prone to constipation or dry stool?",
            "question06": "Is your face flushed or reddish?",
            "question07": "Do you have dry eyes?",
            "question08": "Do you have night sweats?"
          },
          "questionsTypeDampPhlegm": {
            "question01": "Do you feel chest tightness or abdominal fullness?",
            "question02": "Do you feel tightness or tension of your body regardless of the body's position?",
            "question03": "Is your abdomen big and soft?",
            "question04": "Do you have obvious oil secretions from the forehead?",
            "question05": "Are your upper eyelids swollen (bulging slightly)?",
            "question06": "Is there a sticky feeling in your mouth?",
            "question07": "Do you usually have phlegm, especially in the throat?",
            "question08": "Does your tongue have a thick coating?"
          },
          "questionsTypeDampHeat": {
            "question01": "Is your face or nose greasy or shiny?",
            "question02": "Are you prone to acne or sores on the skin?",
            "question03": "Do you have a bitter taste in your mouth or bad breath?",
            "question04": "Do your bowel movements feel incomplete and/or is the stool sticky?",
            "question05": "Do you have hot or burning sensation when you urinate with dark yellow urine? (normal urine should be a pale yellow)",
            "question06": "Do you have yellow-colored vaginal discharge (for female only) or does your scrotum feel sweaty (for male only)?"
          },
          "questionsTypeBloodStasis": {
            "question01": "Do bruises appear on your skin without knowing the cause (such as recent injury)?",
            "question02": "Do you have fine, red-colored veins showing on your cheeks?",
            "question03": "Is there any pain in your body?",
            "question04": "Do you have dark circles around your eyes?",
            "question05": "Is your lip color darker than normal?"
          },
          "questionsTypeQiYu": {
            "question01": "Are you nervous and anxious?",
            "question02": "Are you sentimental and sensitive?",
            "question03": "Are you vulnerable to fear or fright?",
            "question04": "Do you have tenderness or distension below your ribs on either side of the abdomen?",
            "question05": "Do you sigh for no apparent reason?",
            "question06": "Do you feel like there is something caught in your throat that you can't cough up or swallow?"
          },
          "questionsTypeSpecial": {
            "question01": "Do you sneeze when you don't have a cold?",
            "question02": "Do you have a stuffy nose and a runny nose when you don't have a cold?",
            "question03": "Do you have cough and asthma due to seasonal changes, temperature changes, or odors?",
            "question04": "Are you susceptible to allergies (for drugs, food, smells, pollen or when the seasons alternate, climate changes)?",
            "question05": "Is your skin prone to hives or rashes?",
            "question06": "Does your skin turn red from a slight scratch?"
          },
          "social": {
            "facebook": "",
            "github": "",
            "instagram": "",
            "linkedin": "",
            "pinterest": "",
            "soundcloud": "",
            "youtube": "https://youtu.be/w3ZOSYoorvg",
            "twitter": ""
          },
          "strings": {
            "name": "TCM Constitutional Assessment",
            "email": "",
            "companyAddress": "",
            "companyPhone": "",
            "companyCity": "",
            "companyState": "",
            "companyZip": "",
            "companyName": "",
            "title": "",
            "websiteUrl": "https://pacificcollege.edu",
            "instructions": "Please answer the following question based on your experience during the past year. Just choose a point along the scale from Never to Always."
          },
          "pages": [{
                "name": "BackPage",
                "route": "/results",
                "path": "pages/BackPage"
            },
            {
                "name": "FrontPage",
                "route": "/",
                "path": "pages/FrontPage"
            }
          ],
          "about": {
            "description": "# Credits\n## Producer \n- Pacific College of Health and Science\n## Directors\n- Todd Luger\n- Greg Lane\n## Translation and Materials\n- Dong Ji\n## Software Development\n- Miles Exner\n- [Made with Koji](https://withkoji.com)\n---\n## References\n- *Huang Di Nei Jing , Su Wen, Chapter 64*\n- Unschuld, P. (2016). 陰陽二十五人. In *Huang Di Nei Jing Ling Shu: The Ancient Classic on Needle Therapy* (pp. 577-594). Oakland, California: University of California Press. Retrieved April 16, 2020, from [www.jstor.org/stable/10.1525/j.ctv1xxv82.67](www.jstor.org/stable/10.1525/j.ctv1xxv82.67)\n## Research\n- R. Yu, X. Zhao, L. Li, et al.\n*Consistency between traditional Chinese medicine constitution-based classification and genetic classification*\nJournal of Traditional Chinese Medical Sciences (2015) 2, 248e257,\nRetrieved April 16, 2020, from [https://doi.org/10.1016/j.jtcms.2016.01.012](https://doi.org/10.1016/j.jtcms.2016.01.012)\n- Y. Sun, Y Zhao, S. A. Xue, J. Chen.\n*The theory development of traditional\nChinese medicine constitution: a review*\nJournal of Traditional Chinese Medical Sciences (2018) 5, 16e28, Retrieved April 16, 2020, from [https://doi.org/10.1016/j.jtcms.2016.01.012](https://doi.org/10.1016/j.jtcms.2016.01.012)"
          },
          "metadata": {
            "name": "TCM Constitution Test",
            "short_name": "TCM Test",
            "description": "Answer all the questions in the \"Traditional Chinese Medicine Classification\nand Judgment” questionnaire.\n.\nEach question is graded according to 5 levels, the original score and the\nconversion score is calculated, and the constitution type is determined\naccording to the judging criteria.\n",
            "image": "",
            "icon": "logopchs",
            "gaCode": ""
          },
        }

export default Config
