import Config from '../customization/Config'

const Entries = getEntries()

function getEntries() {
    let numEntries = [] // Adjusted for duplicates
    numEntries.push(Object.values(Config.questionsTypeBalanced).length)
    numEntries.push(Object.values(Config.questionsTypeQiXu).length + 3)
    numEntries.push(Object.values(Config.questionsTypeYangXu).length + 1)
    numEntries.push(Object.values(Config.questionsTypeYinXu).length)
    numEntries.push(Object.values(Config.questionsTypeDampPhlegm).length)
    numEntries.push(Object.values(Config.questionsTypeDampHeat).length)
    numEntries.push(Object.values(Config.questionsTypeBloodStasis).length + 1)
    numEntries.push(Object.values(Config.questionsTypeQiYu).length)
    numEntries.push(Object.values(Config.questionsTypeSpecial).length)
    return numEntries
}

const Questions = getQuestions()

function getQuestions() {
    let questions = Object.values(Config.questionsTypeBalanced)
    questions = questions.concat(Object.values(Config.questionsTypeQiXu))
    questions = questions.concat(Object.values(Config.questionsTypeYangXu))
    questions = questions.concat(Object.values(Config.questionsTypeYinXu))
    questions = questions.concat(Object.values(Config.questionsTypeDampPhlegm))
    questions = questions.concat(Object.values(Config.questionsTypeDampHeat))
    questions = questions.concat(Object.values(Config.questionsTypeBloodStasis))
    questions = questions.concat(Object.values(Config.questionsTypeQiYu))
    questions = questions.concat(Object.values(Config.questionsTypeSpecial))
    return questions
}

export { Entries, Questions }