import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Config from '../../customization/Config'
import { Helmet } from 'react-helmet';
import { ContainerBlock, Block, Column } from '../../components/Components'
import { FaArrowAltCircleLeft } from "react-icons/fa"
import {TiSocialTwitterCircular, TiSocialFacebookCircular, TiSocialLinkedinCircular, } from "react-icons/ti"
import { ButtonArrow, ButtonBox, ButtonMenu } from '../../components/Buttons'
import gfm from 'remark-gfm'

import ReactMarkdown from 'react-markdown'

const BlockWNavigationBelow = styled(Block)`
    height: calc(100vh - 100px);
    margin: 0;
    a {
        color: ${() => Config.colors.linkColor};
    }
`

const LabelTab = styled.label`
    display: block;
    padding: 1rem .8rem;
    border-radius: 2px 2px 0 0;
    font-size: 20px;
    font-weight: bold;
    background: #8e44ad;
    cursor: pointer;
    position: relative;
    top: 4px;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    :hover {
        background: #703688;
    }
    :before {
        border: none;
        background-color: inherit;
    }
`

const SocialWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const SocialLink = styled.a`
    color: ${() => Config.colors.linkColor};
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: .5rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    text-decoration: none;
`

const LinkBack = styled(Link)`
    color: ${() => Config.colors.linkColor};
`

class SharePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked0: true,
            checked1: false
        }
    }

    componentDidMount() {
        if (this.props.match != undefined) {
            const { match: { params } } = this.props;
            if (params.page == 'about') {
                this.setState({
                    checked0: false,
                    checked1: true
                })
            }
        }
    }

    onChangeChecked() {
        this.setState({
            checked0: !this.state.checked0,
            checked1: !this.state.checked1
        })
    }

    // Control the margins and column widths with margin and colWidth
    render() {
      
      const appLocation = (window.location != window.parent.location)
          ? document.referrer
          : document.location.href;

        return (
            <ContainerBlock>
                    <Helmet defaultTitle={`${Config.strings.name} | Info`}>
                        <link href={Config.layout.fontFamily} rel="stylesheet" />
                        <link href={Config.layout.companyFontFamily} rel="stylesheet" />
                        <link rel="icon" href={Config.metadata.icon} sizes="32x32" />
                    </Helmet>
                    <BlockWNavigationBelow colWidth="100%">
                        <ul className={"tabs"} role={"tablist"}>
                        <li>
                            <input 
                                type="radio" 
                                name="tabs" 
                                id="tab1" 
                                checked={this.state.checked0} 
                                onChange={this.onChangeChecked.bind(this)}
                            />
                            <LabelTab htmlFor="tab1"
                                role={"tab"} 
                                aria-selected={"true"} 
                                aria-controls={"panel1"} 
                                tabIndex="0">Share</LabelTab>
                            <div id={"tab-content1"}
                                className={"tab-content"} 
                                role={"tabpanel"} 
                                aria-labelledby={"description"} 
                                aria-hidden={"false"}>
                                <SocialWrapper>
                                    <SocialLink
                                        href={`https://twitter.com/intent/tweet?text=${window.encodeURIComponent('What is your Chinese Constitution?')} https://${window.location.hostname}`}
                                        target={'_top'}
                                    >
                                        <TiSocialTwitterCircular size='7em'/>Share on Twitter
                                    </SocialLink>
                                    <SocialLink
                                        href={`https://www.facebook.com/sharer/sharer.php?u=https://${window.location.hostname}`}
                                        target={'_top'}
                                    >
                                        <TiSocialFacebookCircular size='7em'/>Share on Facebook
                                    </SocialLink>
                                    <SocialLink
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://${window.location.hostname}&title=${window.encodeURIComponent('What is your Chinese Constitution?')}&source=LinkedIn`}
                                        target={'_top'}
                                    >
                                        <TiSocialLinkedinCircular size='7em'/>Share on LinkedIn
                                    </SocialLink>
                                </SocialWrapper>
                            </div>
                        </li>
                    
                        <li>
                            <input 
                                type="radio" 
                                name="tabs" 
                                id="tab2"
                                checked={this.state.checked1}
                                onChange={this.onChangeChecked.bind(this)}
                            />
                            <LabelTab htmlFor="tab2"
                                role={"tab"} 
                                aria-selected={"false"} 
                                aria-controls={"panel2"} 
                                tabIndex="0">About</LabelTab>
                            <div id={"tab-content2"} 
                                className={"tab-content"}
                                role={"tabpanel"} 
                                aria-labelledby={"recommendation"} 
                                aria-hidden={"true"}>
                                <ReactMarkdown 
                                    remarkPlugins={[gfm]}
                                    children={Config.about.description}
                                />
                            </div>
                        </li>
                    </ul>
                </BlockWNavigationBelow>
                <ButtonBox colWidth='100%'>
                    <ButtonArrow>
                        <LinkBack to="/">
                            <FaArrowAltCircleLeft size="48"/>
                        </LinkBack>
                    </ButtonArrow>
                    <ButtonMenu destroy={this.destroy}/>
                </ButtonBox>  
            </ContainerBlock>
        );
    }
}

export default SharePage
