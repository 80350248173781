import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import styled from 'styled-components';

let data, list;

const Legend = styled.ul`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -165%);
    @media (max-width: 320px) {
        transform: translate(-50%, -158%);
    }
    display: flex;
    flex-direction: column;
    font-size: 14px;
`

class Chart extends React.Component {
    constructor(props) {
        super(props);

        this.data = [
            {
                color: '#e32727',
                title: 'Balanced',
                value: this.props.result[0]
            },
            {
                color: '#e39327',
                title: 'Qi Deficiency',
                value: this.props.result[1]
            },
            {
                color: '#d4e327',
                title: 'Yang Deficiency',
                value: this.props.result[2]
            },
            {
                color: '#7de327',
                title: 'Yin Deficiency',
                value: this.props.result[3]
            },
            {
                color: '#27e34a',
                title: 'Damp and Phlegm',
                value: this.props.result[4]
            },
            {
                color: '#27dfe3',
                title: 'Damp and Heat',
                value: this.props.result[5]
            },
            {
                color: '#276fe3',
                title: 'Blood Stasis',
                value: this.props.result[6]
            },
            {
                color: '#7327e3',
                title: 'Qi Stagnation',
                value: this.props.result[7]
            },
            {
                color: '#e327ae',
                title: 'Hypersensitive',
                value: this.props.result[8]
            }
        ]

        data = this.data

        list = data.map((entry) => 
                <li 
                    style={{color: entry.color}}
                >
                    {entry.title}
                </li>
        )
    }

  render() {
    return (
        <PieChart
                    style={{
                        fontFamily:
                        '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                        fontSize: '4px',
                    }}
                    label={
                        ({ dataEntry }) =>
                        dataEntry.percentage >= 1 ? Math.round(dataEntry.percentage) + '%' : ''
                    }
                    labelPosition={75}
                    labelStyle={{
                        fill: '#fff',
                        opacity: 1,
                        pointerEvents: 'none',
                        dominantBaseline: 'central', //@TODO remove if when #149 gets implemented
                    }}
                    animate
                    animationDuration={500}
                    animationEasing="ease-out"
                    rounded
                    cx={50}
                    cy={50}
                    data={this.data}
                    lengthAngle={360}
                    lineWidth={20}
                    onClick={undefined}
                    onMouseOut={undefined}
                    onMouseOver={undefined}
                    startAngle={180}
        >
            <Legend>
                {list}
            </Legend>
        </PieChart>

    )
  }
}

export default Chart