function getResult(answers) {
    let answerJson = JSON.stringify(answers)
    console.log("Answers", answerJson)

    let config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: answerJson
        }

    return new Promise((resolve, reject) => {
        // fetch(`${Koji.config.serviceMap.backend}/sync`, config)
        fetch(`https://tcmresults.withkoji.com/sync`, config)
        .then(res => res.json())
        .then(res => {
            resolve(res)
        }).catch((error) => {
            console.log(error)
        })
      });
}

export { getResult }